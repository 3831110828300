<template>
  <div>
    <de v-only-locales="['de-de']"></de>
    <en v-only-locales="['en-gb']"></en>
  </div>
</template>

<script>
export default {
  components: {
    De: () => import('./De'),
    En: () => import('./En')
  }
}
</script>
